import FCLandingNavbar from './components/FCLandingNavbar'
import FCLandingCarousel from './components/FCLandingCarousel'
import FCLandingObituaries from './components/FCLandingObituaries'
import './styles.scss'

export default function LandingPage(){
    return(
        <>
            <FCLandingNavbar />
            <FCLandingCarousel/>
            <div style={{width: '100%', height: 8, backgroundColor: 'rgb(139 96 106)'}}></div>
            <FCLandingObituaries />
            
        </>
    )
}