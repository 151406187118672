import { useState } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FormGroup from 'react-bootstrap/FormGroup' 
import FormLabel from 'react-bootstrap/FormLabel'
import FormControl from 'react-bootstrap/FormControl'
import FormText from 'react-bootstrap/FormText'
import Button from 'react-bootstrap/Button'

import { PatternFormat } from 'react-number-format'

export default function SendForm(){

     const [faxFromName, setFaxFromName] = useState('Humphrey Funeral Service')
     const [faxFromNumber, setFaxFromNumber] = useState('4799685869')
 
     function handleFormSubmit(e){
          e.preventDefault()
     }
     
     return(
          <>
               <Container fluid>
                    <Row>
                         <Col xs={12} md={{span: 6, offset: 3}}>
                              <form className='form' onSubmit={handleFormSubmit}>
                                   <fieldset>
                                        <FormGroup className="mb-3" controlId="formFaxToNumber">
                                             <FormLabel className='text-decoration-underline'>Fax Number or Contact Name</FormLabel>
                                             <PatternFormat name='fax-to-number' className='form-control' format="+1 (###) ###-####" placeholder='+1 (###) ###-####' valueIsNumericString={true} />
                                            
                                             {/*
                                             <FormText className="text-muted">
                                                  Enter the telephone number you would like to send the fax to
                                             </FormText>
                                             */}
                                        </FormGroup>
                                        <FormGroup className="mb-3" controlId="formFaxFromName">
                                             <FormLabel className='text-decoration-underline'>From Name</FormLabel>
                                             <FormControl type="text" name='fax-from-name' value={faxFromName} onChange={(e)=>setFaxFromName(e.target.value)}/>
                                             <FormText className="text-muted">
                                                  Enter the name the fax will be coming from
                                             </FormText>
                                        </FormGroup>
                                        <FormGroup className="mb-3" controlId="formFaxFromNumber">
                                             <FormLabel className='text-decoration-underline'>From Number</FormLabel>
                                             <PatternFormat name='fax-from-number' className='form-control' format="+1 (###) ###-####" valueIsNumericString={true} value={faxFromNumber} onChange={(e)=>setFaxFromNumber(e.target.value)} />
                                             {/*
                                             <FormText className="text-muted">
                                                  Enter the telephone number the fax will be coming from
                                             </FormText>
                                             */}
                                        </FormGroup>
                                        <FormGroup className="mb-3" controlId="formFaxAttachment">
                                             <FormLabel className='text-decoration-underline'>Attachment</FormLabel>
                                             <FormControl type="file" name='fax-attachment' accept='image/*,.pdf' />
                                             {/*
                                             <FormText className="text-muted">
                                                  Select the file you wish to attach as content for your fax message
                                             </FormText>
                                             */}
                                        </FormGroup>
                                   </fieldset>
                                   <Button variant='primary' type='submit'>Submit</Button>
                              </form>
                         </Col>
                    </Row>
               </Container>
          </>
     )
}