import FCNavbarComponent from '../components/landing/FCNavbarComponent'
import FCHomepageHome from '../components/landing/FCHomepageHome'
import FCHomepageFeatures from '../components/landing/FCHomepageFeatures'
import FCHomepagePricing from '../components/landing/FCHomepagePricing'
import FCHomepageAboutUs from '../components/landing/FCHomepageAboutUs'
import FCHomepageFooter from '../components/landing/FCHomepageFooter'


export default function NotAuthorized({onFormLoad}){

     function handleCookieProcedures(){
          console.log("working")
          onFormLoad()
     }

     return (
          <>
               <FCNavbarComponent />
               <FCHomepageHome />
               <FCHomepageFeatures />
               <FCHomepagePricing />
               <FCHomepageAboutUs />
               <FCHomepageFooter />               
          </>
     )
}