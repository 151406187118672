import React from 'react';
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

const router = createBrowserRouter([
     {
          path: '/',
          element: <App />
     }
])

const root = createRoot(document.getElementById('root'))
root.render(
     <RouterProvider router={router} />
)

reportWebVitals(sendToVercelAnalytics);