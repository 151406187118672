import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie';

//styles
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css'

//components
import Loading from './components/Loading';

//pages
import Homepage from './pages/Homepage'
import NotAuthorized from './pages/NotAuthorized'
import ErrorPage from './pages/ErrorPage'
import LandingPage from './pages/landing/LandingPage';

export default function App() { 

     const [cookies, setCookie] = useCookies(['name'])
     const [loading, setLoading] = useState(true)
     const [page, setPage] = useState('home')
     const [landing, setLanding] = useState('false')
     const [auth, setAuth] = useState(false)
     const [error, setError] = useState(true)
     const [errorMessage, setErrorMessage] = useState('')

     useEffect(() => {

          setLoading(false)
          setError(false)
          setLanding(true)
          setPage('home')
          setAuth(true)
          
     }, [loading, page])

     const handleCreateCookie = () => {
          //check credentials
     
          setCookie('test', '123456789')
          setAuth(true)
     }

     
     
     return (
          <>
               {
                    loading === true  ? <Loading /> :
                    error === true ? <ErrorPage error-message={errorMessage} /> :
                    auth === false ? <NotAuthorized onFormLoad={handleCreateCookie}/> :
                    landing === true ? <LandingPage /> : 
                    page === 'home' ? <Homepage /> : null
                    
               }
          </>
     )
}