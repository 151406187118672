import Button from 'react-bootstrap/Button';

const FCSignInForm = () => {

	return (

		<>
			 <form id="resetPasswordForm" name="resetPasswordForm" data-parsley-validate="" autocomplete="off">
                    <input type="email" id="email" name="email" placeHolder="Email" autoFocus required />
            
                    <div className="d-grid gap-2 m-5 mt-3">
					<Button id="btnResetPassword" type="submit">Reset Password</Button>
				</div>
               </form>
		</>

	)

}

export default FCSignInForm