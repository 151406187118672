import Button from 'react-bootstrap/Button'

export default function BtnSignIn({onFormChange}){

     const handleClick = () => {
          onFormChange('FCFormSignIn')
     }
     
    return(
          <>
               <div className='d-grid gap-2 m-2'>
                    <Button variant='secondary' onClick={handleClick}  className='my-1'>Sign In</Button>
               </div>
          </>
    )
}