import Button from 'react-bootstrap/Button'

export default function BtnResetPassword({onFormChange}){

     const handleClick = () => {
          onFormChange('FCFormForgotPassword')
     }
     
    return(
          <>
               <div className='d-grid gap-2 m-2'>
                    <Button variant='secondary' onClick={handleClick} className='my-1'>Reset Password</Button>
               </div>
          </>
    )
}