import { useState } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'react-tabulator/css/tabulator_bootstrap5.min.css'

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import NavbarToggle from 'react-bootstrap/NavbarToggle'
import NavbarCollapse from 'react-bootstrap/NavbarCollapse'
import Nav from 'react-bootstrap/Nav'
import NavLink from 'react-bootstrap/NavLink'
import Button from 'react-bootstrap/Button'

//import Homepage from './Homepage'
import NewFax from './NewFax'

/*
     Example API Call to VOIP.MS
     https://api.voip.ms/api/v1/rest.php?method=getFaxMessages&api_username=james@humphreyfuneral.com&api_password=James0423
*/


export default function FaxDashboard() {

     const [currentPage, setCurrentPage] = useState('home')

     function handleClick(e){
          const pageName = e.target.firstChild.innerText.trim()
          pageName === 'Home' ? setCurrentPage('home') : 
          pageName === 'New Fax' ? setCurrentPage('new') : setCurrentPage('home')
     }

     return (
          <>
               <Navbar variant='light' bg='light'>
                    <Container fluid>
                         
                         <NavbarToggle aria-controls="basic-navbar-nav" />
                         <NavbarCollapse id="basic-navbar-nav">
                              <Nav className="me-auto">
                                   <NavLink onClick={handleClick}><Button style={{pointerEvents: 'none'}} variant='primary'>Fax List <i className="bi bi-list"></i></Button></NavLink>
                                   <NavLink onClick={handleClick}><Button style={{pointerEvents: 'none'}} variant='primary'>New Fax <i className="bi bi-send"></i></Button></NavLink>
                              </Nav>
                         </NavbarCollapse>
                    </Container>
               </Navbar>
               
               <NewFax />
          </>
     )
}