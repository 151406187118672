import Card from 'react-bootstrap/Card'
import CardHeader from 'react-bootstrap/CardHeader'
import CardBody from 'react-bootstrap/CardBody'

export default function ObituarySlide(props){
     
     

     return(
          <>
               <Card className='mx-1 bg-transparent border-0'>
                    <CardHeader className='text-light d-flex justify-content-center border-0 text-nowrap'>{props.name}</CardHeader>
                    <CardBody>
                         <a href="#">
                              <img src={props.photo} width={'100%'} className='rounded'/>
                         </a>
                    </CardBody>
               </Card>
          </>
     )
}