import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import NavbarToggle from 'react-bootstrap/NavbarToggle'
import NavbarCollapse from 'react-bootstrap/NavbarCollapse'
import NavbarBrand from 'react-bootstrap/NavbarBrand'
import NavLink from 'react-bootstrap/NavLink'
import Nav from 'react-bootstrap/Nav'

import hfsLogo from '../img/HumphreyGoogle.png'
import cvmgLogo from '../img/cvmg.png'
import { FaPhoneAlt } from "react-icons/fa";
import { FaNewspaper } from "react-icons/fa6"


export default function FCLandingNavbar(){
    return(
        <>
               <div id="top"></div>
               <div className='sticky-top'>
                    <div className='w-100 text-light pe-1 py-2 d-flex justify-content-center justify-content-md-end navbar-background'>
                         <a className='btn btn-info btn-sm text-light me-1' href="#arrangements">Make Arrangements / Pay Bill</a>
                         <a className='btn btn-primary btn-sm' href="tel:4799684411"> <FaPhoneAlt className='me-2 mb-1' /> (479) 968-4411</a>
                    </div>
                    <Navbar variant='dark' expand='md' className='p-1 py-0 shadow border-bottom border-dark'>
                         <Container fluid>
                              <NavbarBrand href="#top" className='text-light'><img src={hfsLogo} alt='logo' height={90} /></NavbarBrand>
                              <NavbarToggle />
                              <NavbarCollapse>
                              <Nav className='w-100 justify-content-center align-items-center'>
                                   <NavLink href="#obituaries" className='d-flex align-items-center'><FaNewspaper size={20} className='pe-1 me-1'/>Obituaries</NavLink>
                                                     
                                   <NavLink href="#traditionalburial">Burial</NavLink>
                                   <NavLink href="#cremation">Cremation</NavLink>
                                   <NavLink href="#preplan">Pre-Plan</NavLink>
                                   
                              </Nav>

                              <NavLink href="https://www.cvmgardens.com" target='_blank'><img src={cvmgLogo} alt='logo' height={90} /></NavLink>
                              </NavbarCollapse>
                         </Container>
               </Navbar>
          </div>
        </>
    )
}