import { useState } from 'react'
import Button from 'react-bootstrap/Button';

const FCSignInForm = () => {

     const [isSigningIn, setIsSigningIn] = useState(false)

     function handleSignIn(e){
          e.preventDefault();
          setIsSigningIn(true)
          fetch('https://api.example.com/data')
               .then(response => response.json())
               .then(data => {
                    console.log(data);
               })
               .catch(error => {
                    console.error('Error:', error);
          });
     }

	return (

		<>
			<form onSubmit={handleSignIn} method="post" id="signinForm" name="signinForm" data-parsley-validate="" autoComplete="false">
				<input type="email" id="email" name="email" placeholder="Email" required autoFocus autoComplete="off" />
				<div className="mb-3">
					<div id="password-show"><span className="material-icons">visibility</span></div>
					<input type="password" id="password" name="password" placeholder="Password" required autoComplete="off" />
				</div>
				<div className="d-grid gap-2 m-5 mt-3">
                         {
                              isSigningIn === false ? <Button type='submit'>Sign In</Button> :
                              <Button type='submit' disabled>Signing In, Please Wait..</Button>
                         }
					
				</div>
			</form>
		</>

	)

}

export default FCSignInForm