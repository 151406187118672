import Container from 'react-bootstrap/Container'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ObituarySlide from './ObituarySlide'
import imgJames from '../img/James.jpeg'

export default function FCLandingObituaries(){

     const responsive = {
          superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 2000 },
            items: 5
          },
          desktop: {
            breakpoint: { max: 2000, min: 1024 },
            items: 4
          },
          tablet: {
            breakpoint: { max: 1024, min: 800 },
            items: 3
          },
          mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 2
          }
        };

    return(
        <>
          <Container fluid className='mt-4'>
               <h1 className='text-light d-flex justify-content-center'>Obituaries</h1>
               <div id='obituaries' className='mt-2'>
                    <Carousel className=' bg-secondary' responsive={responsive} infinite={true}>
                         <ObituarySlide name='James M. Humphrey' photo={imgJames}/>
                         <ObituarySlide name='James M. Humphrey' photo={imgJames}/>
                         <ObituarySlide name='James M. Humphrey' photo={imgJames}/>
                         <ObituarySlide name='James M. Humphrey' photo={imgJames}/>
                         <ObituarySlide name='James M. Humphrey' photo={imgJames}/>
                         <ObituarySlide name='James M. Humphrey' photo={imgJames}/>
                    </Carousel>
               </div>
          </Container>

        </>
    )
}