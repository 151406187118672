import Button from 'react-bootstrap/Button';

const FCSignInForm = () => {

	return (

		<>
			<form method="post" id="activationForm" name="activationForm" data-parsley-validate="" autocomplete="off" action="index.php">
                <input type="text1" id="key" className="mb-3" name="key" placeHolder="Key" autoFocus required autocomplete="off" />
                <div className='d-grid gap-2 m-5 mt-3'>
                    <Button id="btnActivate" type="submit" className="my-1">Activate</Button>
                    <Button id='btnResendKey' className='btn btn-warning w-100 my-1'> <i className="fas fa-key"></i> Resend Key</Button>
               </div>
              </form>
		</>

	)

}

export default FCSignInForm