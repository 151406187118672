import {useState} from 'react'
import Button from 'react-bootstrap/Button'
import FCFormSignIn from '../forms/FCFormSignIn'
import FCFormForgotPassword from '../forms/FCFormForgotPassword'
import FCFormRegistration from '../forms/FCFormRegistration'
import FCFormActivation from '../forms/FCFormActivation'
import BtnRegistration from '../buttons/BtnRegistration'
import BtnSignIn from '../buttons/BtnSignIn'
import BtnForgotPassword from '../buttons/BtnForgotPassword'
import BtnActivation from '../buttons/BtnActivation'

const FCHomepageHome = () => {

     const [showForm, setShowForm] = useState('FCFormSignIn')

     const handleFormChange =  (formName) => {
          setShowForm(formName)
     }

	return (

		<>
			<section id="home" className="cover-1">
        		<div className="mx-1 mt-5 py-md-5">
          			<div className="row">

						<div id="formContent" className="col-12 col-lg-6 h-100 mt-3 mb-5 pt-3 mx-auto custom-shadow">
							<h3 className="text-uppercase mt-4 mb-4"> Signin Form </h3>
				
							<div className="bs-callout bs-callout-warning d-none">
								<h4> </h4>
								<p> </p>
							</div>

							<div className="bs-callout bs-callout-info d-none">
								<h4> </h4>
								<p> </p>
							</div>

							{
								showForm === 'FCFormSignIn' ? 
                                             <>
                                                  <FCFormSignIn />
                                                  <div id="formFooter">
                                                       <BtnRegistration onFormChange={handleFormChange} />
                                                       <BtnForgotPassword onFormChange={handleFormChange} />
                                                       <BtnActivation onFormChange={handleFormChange} />
                                                  </div>
                                                  
                                             </>
                                        : 
                                        showForm === 'FCFormForgotPassword' ? 
                                             <>
                                                  <FCFormForgotPassword />
                                                  <div id="formFooter">
                                                       <BtnSignIn onFormChange={handleFormChange} />
                                                       <BtnRegistration onFormChange={handleFormChange} />
                                                       <BtnActivation onFormChange={handleFormChange} />
                                                  </div>
                                             </> 
                                        :
                                        showForm === 'FCFormActivation' ? 
                                             <>
                                                  <FCFormActivation />
                                                  <div id="formFooter">
                                                       <BtnSignIn onFormChange={handleFormChange} />
                                                       <BtnForgotPassword onFormChange={handleFormChange} />
                                                       <BtnRegistration onFormChange={handleFormChange} />                                                       
                                                  </div>
                                             </> 
                                        :
                                        showForm === 'FCFormRegistration' ? 
                                             <>
                                                  <FCFormRegistration />
                                                  <div id="formFooter">
                                                       <BtnSignIn onFormChange={handleFormChange} />
                                                       <BtnForgotPassword onFormChange={handleFormChange} />                                                       
                                                       <BtnActivation onFormChange={handleFormChange} />
                                                  </div>
                                             </> 
                                        :
                                             <div>There was an error loading the form</div>
							}
						</div>
            		
            			<div className="col-12 col-lg-6 p-5 text-center">
              				<h3 className="jumbotron-heading text-white">Still in Development</h3>
              				<p className="lead">We are still in development stages.  At this time there is no support.  Do not use real data.  Use this system at your own risk.</p>
              				
              				<Button href="account_registration.php" variant="success" size="lg">Register Now</Button>	
            			</div>
          			</div>
        		</div>
      		</section>
		</>

	)

}

export default FCHomepageHome