import Button from 'react-bootstrap/Button'

export default function BtnRegistration({onFormChange}){

     const handleClick = () => {
          onFormChange('FCFormRegistration')
     }
     
    return(
          <>
               <div className='d-grid gap-2 m-2'>
                    <Button variant='secondary' onClick={handleClick}  className='my-1'>Register Now</Button>
               </div>
          </>
    )
}