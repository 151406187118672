import Button from 'react-bootstrap/Button';

const FCSignInForm = () => {

	return (

		<>
			<form method="post" id="registrationForm" name="registrationForm" data-parsley-validate="" autocomplete="off" action="route.php">
                    <input type="text" id="first" name="first" placeHolder="First Name" autoFocus required />
		      	<input type="text" id="last" name="last" placeHolder="Last Name" required />
                    <input type="email" id="email" name="email" placeHolder="Email" required />
                    <div>
                         <i id="password-show" className="fas fa-eye fa-lg position-absolute me-n25rem mt-4"></i>
                         <input type="password" id="password" name="password" placeHolder="Password" required />
              
                    </div>
                    <div class="mb-3">
                         <i id="confirm-password-show" className="fas fa-eye fa-lg position-absolute me-n25rem mt-4"></i>
                         <input type="password" id="confirm-password" name="confirm-password" placeHolder="Confirm Password" required />
              
                    </div>
                    <div className="d-grid gap-2 m-5 mt-3">
					<Button id="btnRegister" type="submit">Register</Button>
				</div>
		    	</form>
		</>

	)

}

export default FCSignInForm