import Carousel from 'react-bootstrap/Carousel'
import CarouselItem from 'react-bootstrap/CarouselItem'
import imgChapel from '../img/chapel-of-light.jpg'
import imgDinning from '../img/dinning.jpg'
import imgBuilding from '../img/building.png'

export default function FCLandingCarousel(){
    return(
        <>
            <Carousel controls={false} interval={8000} indicators={false} style={{maxHeight: 500, overflow: 'hidden'}}>
                <CarouselItem>
                    <div className='position-absolute text-light'>we aren't like the other guys</div>
                    <img src={imgChapel} className='w-100' alt='test'/>
                </CarouselItem>
                <CarouselItem>
                    <img src={imgBuilding} className='w-100' alt='test'/>
                </CarouselItem>
                <CarouselItem>
                    <img src={imgDinning} className='w-100' alt='test'/>
                </CarouselItem>                
            </Carousel>
        </>
    )
}