import { useState } from 'react'

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import NavbarBrand from 'react-bootstrap/NavbarBrand'
import NavbarToggle from 'react-bootstrap/NavbarToggle'
import NavbarCollapse from 'react-bootstrap/NavbarCollapse'
import Nav from 'react-bootstrap/Nav'
import NavLink from 'react-bootstrap/NavLink'

import hfsLogo from '../img/hfs-logo.png'

import Dashboard from './Dashboard'
import AtNeed from './AtNeed'
import PreNeed from './PreNeed'
import ABA from './ABA'
import Area from './Area'
import Tasks from './Tasks'
import Faxes from './Faxes'
import Profile from './Profile'
import CVMG from './CVMG'

export default function Homepage(){
     
     const [page, setPage] = useState()

     function handleClick(e){
          setPage(e.target.innerText.trim())

     }
     
     return (
          <>
               <Container fluid className='mt-2'>
                    <Navbar expand='lg' variant='light'  className='rounded custom-bg px-3'>
                         <NavbarBrand onClick={handleClick} ><img src={hfsLogo} alt='logo' height={60} /></NavbarBrand>
                              
                              <NavbarToggle></NavbarToggle>
                              <NavbarCollapse>
                                   <Nav>
                                        <NavLink onClick={handleClick}>At-Need</NavLink>
                                        <NavLink onClick={handleClick}>Pre-Need</NavLink>
                                        <NavLink onClick={handleClick}>ABA</NavLink>
                                        <NavLink onClick={handleClick}>Area DB</NavLink>
                                        <NavLink onClick={handleClick}>Tasks</NavLink>
                                        <NavLink onClick={handleClick}>Faxes</NavLink>
                                        <NavLink onClick={handleClick}>CVMG</NavLink>
                                        <NavLink onClick={handleClick}>Profile</NavLink>
                                   </Nav>
                              </NavbarCollapse>
                              
                             
                         
                    </Navbar>
               </Container>
               <Container fluid className='mt-2'>
                    {
                         page === '' ? <Dashboard /> :
                         page === 'At-Need' ? <AtNeed /> :
                         page === 'Pre-Need' ? <PreNeed /> :
                         page === 'ABA' ? <ABA /> :
                         page === 'Area DB' ? <Area /> :
                         page === 'Tasks' ? <Tasks /> :
                         page === 'Faxes' ? <Faxes /> :
                         page === 'Profile' ? <Profile /> :
                         page === 'CVMG' ? <CVMG /> : <Dashboard />
                    }
               </Container>
          </>
     )
}